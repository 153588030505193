.proposals {
  padding-top: em(225, $fz);
  padding-bottom: em(225, $fz);

  @include media(tablet) {
    padding-top: em(30, $fz);
    padding-bottom: 0;
  }

  &__wrapper {
    align-items: start;
  }

  &__list {
    margin-top: em(130, $fz);

    @include media(tablet) {
      margin-top: em(30, $fz);
    }
  }

  &__title {
    text-align: left;

    .title {
      &__text {
        font-family: 'Oswald', 'Roboto', sans-serif;

        @include media(tablet) {
          $fz: 30;
          font-size: em($fz, $fz_base);
        }
      }
    }
  }

  .list {
    flex-wrap: wrap;

    &__item {
      width: 100%;
      max-width: em(450, $fz);

      &:not(:nth-last-child(1)),
      &:not(:nth-last-child(2)),
      &:not(:nth-last-child(3)) {
        margin-bottom: em(30, $fz);
      }

      &:not(:nth-child(3n)) {
        margin-right: em(30, $fz);

        @include media(tablet) {
          margin-right: 0;
        }
      }

      &:not(:last-child) {
        @include media(tablet) {
          margin-bottom: em(10, $fz);
        }
      }
    }

    &__proposal {
      width: 100%;
    }
  }
}
