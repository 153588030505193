.footer-menu {
  @include media(tablet) {
    flex-direction: column;
  }

  &__item {
    &:not(:last-child) {
      position: relative;
      margin-right: em(25, $fz);

      @include media(tablet) {
        margin-right: 0;
        margin-bottom: 5px;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: em(-13, $fz);
        width: 1px;
        height: em(13, $fz);
        background-color: darken(color(primary), 40%);
        transform: translateY(-50%);

        @include media(tablet) {
          top: 100%;
          right: 0;
          width: 100%;
          height: 1px;
          transform: translateY(2px);
        }
      }
    }
  }
}

.footer-menu-link {
  &__text {
    color: darken(color(primary), 40%);
  }
}
