* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font: inherit;
}

html {
  scroll-behavior: smooth;
}

main {
  flex: 1 0 auto;
}

picture {
  display: flex;
  width: 100%;
  line-height: 0;
}

img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

a {
  text-decoration: none;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.6;
  }
}

button {
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;

  &:focus {
    outline: none;
  }
}

ul {
  display: flex;
  align-items: center;
  list-style: none;
}

input {
  outline: unset;
  border: unset;

  &::placeholder {
    opacity: 0.6;
  }
}

footer {
  flex: 0 0 auto;
}
