/*
  --- Generates em sizes by px ---
  $args... - includes count of size with or not with a type of size (example: 16px, 16)
*/
@function strip-units($unit) {
  @return $unit / ($unit * 0 + 1);
}

@function em($args...) {
  $context: strip-units(nth($args, length($args)));
  $result: ();
  $separator: list_separator($args);

  @for $i from 1 through length($args) - 1 {
    $value: nth($args, $i);
    @if type-of($value) == 'number' {
      $value: strip-units($value);
      $result: append($result, $value / $context * 1em, $separator);
    } @else if type-of($value) == 'list' {
      $result: append($result, em(append($value, $context)...), $separator);
    } @else {
      $result: append($result, $value, $separator);
    }
  }

  @return if(length($result) == 1, nth($result, 1), $result);
}

/*
  --- Generates vw sizes by px ---
  $args... - includes count of size with or not with a type of size (example: 16, 1920)
*/
@function vw($values...) {
  $size: strip-units(nth($values, length($values)));
  $result: ();
  $separator: list_separator($values);

  @for $i from 1 through length($values) - 1 {
    $value: nth($values, $i);
    @if type-of($value) == 'number' {
      $value: strip-units($value);
      $result: append($result, $value / $size * 100vw, $separator);
    } @else if type-of($value) == 'list' {
      $result: append($result, vw(append($value, $size)...), $separator);
    } @else {
      $result: append($result, $value, $separator);
    }
  }

  @return if(length($result) == 1, nth($result, 1), $result);
}

/*
  --- Generates a color by template colors ---
  $name - includes a name for color (example: secondary, theme)
*/
@function color($name) {
  @if map-has-key($colors, $name) {
    @return map-get($colors, $name);
  }

  @return null;
}
