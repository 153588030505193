.banner {
  padding-top: em(270, $fz);
  padding-bottom: em(90, $fz);
  background-image: url('/img/bg.jpg');  
  position: relative;
  

  @include media(tablet, min) {
    min-height: 100vh;
    overflow: hidden;
  }

  @include media(tablet) {
    $fz: 19;
    padding-top: em(145, $fz);
    padding-bottom: em(45, $fz);
  }

  &__wrapper {
    //position: relative;

    @include media(tablet) {
      flex-direction: column;
    }
  }

  &__col {
    overflow: hidden;

    &:nth-child(1) {
      @include media(tablet) {
        margin-top: em(30, $fz);
        text-align: center;
      }
      @include media(desktop, min) {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }    
  }

  &__swiper {
    position: absolute;
    right: em(55, $fz);
    bottom: 0;
    top: 150px;
    left: 50%;
    @include media(tablet) {
      position: static;
      order: -1;
    }
    @include media(desktop) {
      top: 90px;
    }
  }

  &__slide {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    @include media(desktop) {
      height: 290px;
    }
  }

  &__socials {
    position: absolute;
    right: 0;

    @include media(tablet) {
      position: static;
      margin-top: em(30, $fz);
    }
  }

  &__title {
    width: 100%;
    max-width: em(570, $fz);
  }

  &__link {
    width: 100%;
    max-width: em(300, $fz);
    margin-top: em(85, $fz);

    @include media(tablet) {
      $fz: 19;
      max-width: em(190, $fz);
      margin-top: em(30, $fz);
    }
  }

  &__pic {
    max-width: em(862, $fz);

    @include media(tablet) {
      $fz: 19;
      max-width: em(320, $fz);
    }

    @include media(smartphone) {
      $fz: 12;
      max-width: em(280, $fz);
    }
  }

  &__title {
    .title {
      &__text {
        color: color(primary);

        @include media(tablet) {
          $fz: 36;
          font-size: em($fz, $fz_base);
        }
      }
    }
  }

  .socials {
    flex-direction: column;
    align-self: flex-end;

    @include media(tablet) {
      flex-direction: row;
      align-self: inherit;
    }

    &__item {
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: em(20, $fz);

        @include media(tablet) {
          margin-right: em(15, $fz);
          margin-bottom: 0;
        }
      }
    }
  }

  .link {
    @include media(tablet) {
      $fz: 19;
      border-radius: em(5, $fz);
      transform: translateY(-2px);
      display: inline-block;
    }

    @include media(smartphone) {
      $fz: 12;
      border-radius: em(3, $fz);
    }

    &__text {
      @include media(tablet) {
        $fz: 12;
        font-size: em($fz, $fz_base);;
      }
    }

    &--is-button {
      @include media(tablet) {
        padding: em(8, $fz);
      }
    }
  }
}

.swiper-fade {
  &.swiper-free-mode {
    .swiper-slide {
      transition-timing-function: ease-out;
    }
  }
  .swiper-slide {
    pointer-events: none;
    transition-property: opacity;
    .swiper-slide {
      pointer-events: none;
    }
  }
  .swiper-slide-active {
    &,
    & .swiper-slide-active {
      pointer-events: auto;
    }
  }
}


.slide__image {
  min-height: 100%;
  width: auto;
  max-width: 100%;
  display: block;
}

.canvas__wrapper {

  @include media(tablet) {
    position: relative;
    height: 0;
    padding-bottom: 100%;
    width: 100%;
  }

}
