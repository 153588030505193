.proposal {
  display: flex;
  align-items: center;
  padding-top: em(25, $fz);
  padding-bottom: em(25, $fz);
  border: 1px solid adjust-color(color(theme), $hue: 5, $saturation: 20%, $lightness: 23%);
  border-radius: em(10, $fz);
  background-color: color(primary);

  &__title {
    max-width: em(160, $fz);
    margin-left: em(70, $fz);

    @include media(tablet) {
      margin-left: em(30, $fz);
    }
  }

  &__icon {
    margin-left: em(30, $fz);

    @include media(tablet) {
      margin-left: em(15, $fz);
    }
  }

  &__title {
    .title {
      &__text {
        $fz: 22;
        font-family: 'Roboto', sans-serif;
        font-size: em($fz, $fz_base);

        @include media(tablet) {
          $fz: 18;
          font-size: em($fz, $fz_base);
        }
      }
    }
  }
}

.proposal-icon {
  flex-shrink: 0;
  width: em(120, $fz);
  height: em(120, $fz);

  @include media(tablet) {
    width: em(45, $fz);
    height: em(45, $fz);
  }
}
